import React, { useEffect, useMemo, useState } from 'react'
import { PRODUCTS_PATH } from './products/Products'
import { CATEGORIES_PATH } from './categories/Categories'
import { CLIENTS_PATH } from './clients/Clients'
import { BANNERS_PATH } from './banners/Banners'

import { Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { ADMIN_DASHBOARD_PATH } from './routes/StoreRoutes'
import './Dashboard.less'
import { SETTINGS_PATH } from './storeSettings/Routes'
import { STORE_GENERAL_SETTINGS_PATH } from './storeSettings/storeGeneralSettings'
import { EMAIL_SUBSCRIPTIONS_PATH } from './emailSubscriptions/Routes'
import { SUBSCRIPTIONS_PATH } from './emailSubscriptions/subscriptions'
import { DISCOUNTS_PATH } from './discounts/Routes'
import { OFFERS_PATH } from './discounts/offers'
import SubMenu from 'antd/lib/menu/SubMenu'
import { ABANDON_CHECKOUTS_PATH, ORDERS_PATH } from './orders'
import { ARTICLES_PATH } from './blog/Articles'
import { BLOG_CATEGORY_PATH } from './blog/categories/BlogCategory'
import {
  GoldFilled,
  FileImageFilled,
  ShoppingFilled,
  UsergroupAddOutlined,
  PictureFilled,
  TagsFilled,
  SettingFilled,
  MailFilled,
  TeamOutlined,
  ProjectFilled,
  TrophyFilled,
  SolutionOutlined,
  BookFilled,
  HomeFilled,
  AppstoreAddOutlined,
  FileFilled,
  ContactsFilled,
  BankOutlined,
  AppstoreFilled,
  MenuOutlined,
  CarryOutOutlined,
  LineChartOutlined,
} from '@ant-design/icons'
import { useHistory, useLocation } from 'react-router-dom'
import { SERVICES_PATH } from './services/Services'
import { PROJECTS_PATH } from './projects/Projects'
import { TESTIMONIALS_PATH } from './testimonials/Testimonials'
import { TEAM_MEMBERS_PATH } from './teamMembers/TeamMembers'
import { FILES_PATH } from './files/Files'
import { GALLERY_PATH } from './galleries/Gallery'
import { ROOM_TYPE_PATH } from './roomTypes/RoomType'
import { CONTACT_REQUESTS_PATH } from './contacts/ContactRequests'
import { Module } from 'core/domain/store/module'
import { User } from 'core/application/account/loginUser'
import useLoginUser from '../common/hooks/useLoginUser'
import { BRANDS_PATH } from './brands/Brands'
import { JOB_CATEGORIES_PATH } from './careers/categories/JobCategories'
import { JOB_POSITIONS_PATH } from './careers/jobs/JobPositions'
import { RESTAURANT_MENU_PATH } from './restaurants/Menu'
import ROUTE_PATHS from '../common/routePaths'
import { FUTURE_APPOINTMENTS_PATH } from './appointments/futuredAppointments/FutureAppointments'
import { REPORTS_PATH } from './reports/Reports'

export interface MenuItem {
  name: string
  key: number
  path: string
  icon?: React.ReactNode
  children?: MenuItem[]
}

const moduleMenuItemMap = new Map<Module, MenuItem>([
  [
    Module.Home,
    {
      name: 'dashboard.home',
      key: Module.Home,
      path: `${ADMIN_DASHBOARD_PATH}`,
      icon: <HomeFilled />,
    },
  ],
  [
    Module.Orders,
    {
      name: 'general.orders',
      key: Module.Orders,
      path: '',
      icon: <ShoppingFilled />,
      children: [
        {
          name: 'general.allOrders',
          key: 21,
          path: `${ADMIN_DASHBOARD_PATH}${ORDERS_PATH}`,
        },
        {
          name: 'general.abandonCheckouts',
          key: 22,
          path: `${ADMIN_DASHBOARD_PATH}${ORDERS_PATH}/${ABANDON_CHECKOUTS_PATH}`,
        },
      ],
    },
  ],
  [
    Module.Products,
    {
      name: 'dashboard.products.title',
      key: Module.Products,
      path: ``,
      icon: <AppstoreAddOutlined />,
      children: [
        {
          name: 'general.allProducts',
          key: 33,
          path: `${ADMIN_DASHBOARD_PATH}${PRODUCTS_PATH}`,
        },
        {
          name: 'general.categories',
          key: 34,
          path: `${ADMIN_DASHBOARD_PATH}${CATEGORIES_PATH}`,
        },
        {
          name: 'general.brands',
          key: 35,
          path: `${ADMIN_DASHBOARD_PATH}${BRANDS_PATH}`,
        },
      ],
    },
  ],
  [
    Module.RestaurantMenu,
    {
      name: 'dashboard.menues.title',
      key: Module.RestaurantMenu,
      path: `${ADMIN_DASHBOARD_PATH}${RESTAURANT_MENU_PATH}`,
      icon: <MenuOutlined />,
    },
  ],
  [
    Module.Clients,
    {
      name: 'dashboard.clients_table.title',
      key: Module.Clients,
      path: `${ADMIN_DASHBOARD_PATH}${CLIENTS_PATH}`,
      icon: <UsergroupAddOutlined />,
    },
  ],
  [
    Module.Banners,
    {
      name: 'dashboard.banners.title',
      key: Module.Banners,
      path: `${ADMIN_DASHBOARD_PATH}${BANNERS_PATH}`,
      icon: <PictureFilled />,
    },
  ],
  [
    Module.Appointment,
    {
      name: 'dashboard.appointments.title',
      key: Module.Appointment,
      path: `${ROUTE_PATHS.APPOINTMENTS}`,
      icon: <CarryOutOutlined />,
      children: [
        {
          name: 'List of Appointments',
          key: 36,
          path: `${ROUTE_PATHS.APPOINTMENTS}`,
        },
        {
          name: 'Future Appointments',
          key: 37,
          path: `${ADMIN_DASHBOARD_PATH}${FUTURE_APPOINTMENTS_PATH}`,
        },
      ],
    },
  ],
  [
    Module.Offers,
    {
      name: 'dashboard.discounts',
      key: Module.Offers,
      path: `${ADMIN_DASHBOARD_PATH}${DISCOUNTS_PATH}/${OFFERS_PATH}`,
      icon: <TagsFilled />,
      children: [
        {
          name: 'dashboard.discounts',
          key: 34,
          path: `${ADMIN_DASHBOARD_PATH}${DISCOUNTS_PATH}/${OFFERS_PATH}`,
        },
        {
          name: 'dashboard.reports.title',
          key: 35,
          path: `${ADMIN_DASHBOARD_PATH}${DISCOUNTS_PATH}/${REPORTS_PATH}`,
        },
      ],
    },
  ],
  [
    Module.Reports,
    {
      name: 'dashboard.reports.title',
      key: Module.Reports,
      path: `${ADMIN_DASHBOARD_PATH}${REPORTS_PATH}`,
      icon: <LineChartOutlined />,
    },
  ],
  [
    Module.Settings,
    {
      name: 'general.config',
      key: Module.Settings,
      path: `${ADMIN_DASHBOARD_PATH}${SETTINGS_PATH}/${STORE_GENERAL_SETTINGS_PATH}`,
      icon: <SettingFilled />,
    },
  ],
  [
    Module.EmailSubscriptions,
    {
      name: 'general.email_subscriptions',
      key: Module.EmailSubscriptions,
      path: `${ADMIN_DASHBOARD_PATH}${EMAIL_SUBSCRIPTIONS_PATH}/${SUBSCRIPTIONS_PATH}`,
      icon: <MailFilled />,
    },
  ],
  [
    Module.Blog,
    {
      name: 'dashboard.blog.title',
      key: Module.Blog,
      path: ``,
      icon: <BookFilled />,
      children: [
        {
          name: 'dashboard.blog.article',
          key: 92,
          path: `${ADMIN_DASHBOARD_PATH}${ARTICLES_PATH}`,
        },
        {
          name: 'dashboard.blog.category',
          key: 93,
          path: `${ADMIN_DASHBOARD_PATH}${BLOG_CATEGORY_PATH}`,
        },
      ],
    },
  ],
  [
    Module.Services,
    {
      name: 'dashboard.services.name',
      key: Module.Services,
      path: `${ADMIN_DASHBOARD_PATH}${SERVICES_PATH}`,
      icon: <SolutionOutlined />,
    },
  ],
  [
    Module.Projects,
    {
      name: 'dashboard.projects.name',
      key: Module.Products,
      path: `${ADMIN_DASHBOARD_PATH}${PROJECTS_PATH}`,
      icon: <ProjectFilled />,
    },
  ],
  [
    Module.Testimonials,
    {
      name: 'dashboard.testimonials.name',
      key: Module.Testimonials,
      path: `${ADMIN_DASHBOARD_PATH}${TESTIMONIALS_PATH}`,
      icon: <TrophyFilled />,
    },
  ],
  [
    Module.TeamMembers,
    {
      name: 'dashboard.sites.team_members.title',
      key: Module.TeamMembers,
      path: `${ADMIN_DASHBOARD_PATH}${TEAM_MEMBERS_PATH}`,
      icon: <TeamOutlined />,
    },
  ],
  [
    Module.Files,
    {
      name: 'dashboard.sites.files.title',
      key: Module.Files,
      path: `${ADMIN_DASHBOARD_PATH}${FILES_PATH}`,
      icon: <FileFilled />,
    },
  ],
  [
    Module.Galleries,
    {
      name: 'dashboard.galleries.name',
      key: Module.Galleries,
      path: `${ADMIN_DASHBOARD_PATH}${GALLERY_PATH}`,
      icon: <FileImageFilled />,
    },
  ],
  [
    Module.RoomTypes,
    {
      name: 'dashboard.room_types.name',
      key: Module.RoomTypes,
      path: `${ADMIN_DASHBOARD_PATH}${ROOM_TYPE_PATH}`,
      icon: <GoldFilled />,
    },
  ],
  [
    Module.Contacts,
    {
      name: 'dashboard.contact_requests.title',
      key: Module.Contacts,
      path: `${ADMIN_DASHBOARD_PATH}${CONTACT_REQUESTS_PATH}`,
      icon: <ContactsFilled />,
    },
  ],
  [
    Module.ManagementHome,
    {
      name: 'dashboard.management.home.title',
      key: Module.ManagementHome,
      path: `${ADMIN_DASHBOARD_PATH}`,
      icon: <HomeFilled />,
    },
  ],
  [
    Module.ManagementTheme,
    {
      name: 'dashboard.management.theme.title',
      key: Module.ManagementTheme,
      path: `${ADMIN_DASHBOARD_PATH}themes`,
      icon: <AppstoreFilled />,
    },
  ],
  [
    Module.Jobs,
    {
      name: 'dashboard.jobs.title',
      key: Module.Jobs,
      path: ``,
      icon: <ShoppingFilled />,
      children: [
        {
          name: 'dashboard.jobs.positions',
          key: 94,
          path: `${ADMIN_DASHBOARD_PATH}${JOB_POSITIONS_PATH}`,
        },
        {
          name: 'dashboard.jobs.categories',
          key: 95,
          path: `${ADMIN_DASHBOARD_PATH}${JOB_CATEGORIES_PATH}`,
        },
      ],
    },
  ],
  [
    Module.PopUp,
    {
      name: 'dashboard.store_settings.pop_up_settings',
      key: Module.PopUp,
      path: ROUTE_PATHS.PAGE_SECTIONS,
    },
  ],
  [
    Module.Companies,
    {
      name: 'dashboard.companies.title',
      key: Module.Companies,
      path: ROUTE_PATHS.COMPANIES,
      icon: <BankOutlined />,
    },
  ],
  // [Module.Reports, {
  //   name: "dashboard.reports.title",
  //   key: Module.Reports,
  //   path: `${ADMIN_DASHBOARD_PATH}${REPORTS_PATH}`,
  //   icon: <LineChartOutlined />,
  //   children: [
  //     {
  //       name: "dashboard.reports.title",
  //       key: 96,
  //       path: `${ADMIN_DASHBOARD_PATH}${REPORTS_PATH}`
  //     },
  //     {
  //       name: "dashboard.discount_codes.title",
  //       key: 97,
  //       path: `${ADMIN_DASHBOARD_PATH}${REPORTS_PATH}/${DISCOUNT_CODE_PATH}`
  //     },
  //     {
  //       name: "dashboard.offers.title",
  //       key: 98,
  //       path: `${ADMIN_DASHBOARD_PATH}${REPORTS_PATH}/${OFFERS_ORDER_PATH}`
  //     },
  //     {
  //       name: "dashboard.offers.deal_of_the_day",
  //       key: 99,
  //       path: `${ADMIN_DASHBOARD_PATH}${REPORTS_PATH}/${DEAL_OF_DAY_PATH}`
  //     }
  //   ]
  // }]
])

const getUserMenu = (user: User): MenuItem[] => user.modules.map((x) => moduleMenuItemMap.get(x.module)!)

export const DesktopNavigation: React.FC<any> = () => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const [user] = useLoginUser()

  const [activeKey, setActiveKey] = useState<string[] | undefined>()

  const userMenu = React.useMemo(() => getUserMenu(user), [user])

  useEffect(() => {
    const pathname = location.pathname

    const found = userMenu.find(
      (x) => x.path === pathname || (x.children && x.children.some((child) => child.path === pathname)),
    )

    if (found) {
      if (found.children) {
        const childItem = found.children.find((c) => c.path === pathname)
        if (childItem) setActiveKey([childItem.key.toString()])
      } else {
        setActiveKey([found.key.toString()])
      }
    }
  }, [location, userMenu])

  return (
    <>
      <Menu selectedKeys={activeKey} mode="horizontal" className="header nav-items main-navigation">
        {userMenu.map((item: MenuItem) =>
          item.children ? (
            <SubMenu key={item.key} title={t(item.name)} icon={item.icon}>
              {item.children.map((child) => (
                <Menu.Item
                  style={{ margin: '0' }}
                  key={child.key}
                  icon={<></>}
                  onClick={() => {
                    history.push(child.path)
                  }}
                >
                  {t(child.name)}
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item
              style={{ margin: '0' }}
              key={item.key}
              icon={item.icon}
              onClick={() => {
                history.push(item.path)
              }}
            >
              {t(item.name)}
            </Menu.Item>
          ),
        )}
      </Menu>
    </>
  )
}

export const MobileNavigation: React.FC<any> = () => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const [user] = useLoginUser()

  const [activeKey, setActiveKey] = useState<string[] | undefined>()

  const userMenu = useMemo(() => getUserMenu(user), [user])

  useEffect(() => {
    const pathname = location.pathname
    const found = userMenu.find(
      (x) => x.path === pathname || (x.children && x.children.some((child) => child.path === pathname)),
    )

    if (found) {
      if (found.children) {
        const childItem = found.children.find((c) => c.path === pathname)
        if (childItem) {
          setActiveKey([childItem.key.toString()])
        }
      } else {
        setActiveKey([found.key.toString()])
      }
    }
  }, [location])

  return (
    <>
      <div className="nav-sider" style={{ height: '100%' }}>
        <div className="nav-logo">
          <a className={'text-bold p-1'} target={'_blank'} href={user.siteUrl}>
            {user.storeName}
          </a>
        </div>

        <Menu selectedKeys={activeKey} mode="inline" className="header p-0 nav-items">
          {userMenu.map((item: MenuItem) =>
            item.children ? (
              <SubMenu key={item.key} title={t(item.name)} icon={item.icon}>
                {item.children.map((child) => (
                  <Menu.Item
                    style={{ margin: '0' }}
                    key={child.key}
                    icon={<></>}
                    onClick={() => {
                      history.push(child.path)
                    }}
                  >
                    {t(child.name)}
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item
                style={{ margin: '0' }}
                key={item.key}
                icon={item.icon}
                onClick={() => {
                  history.push(item.path)
                }}
              >
                {t(item.name)}
              </Menu.Item>
            ),
          )}
        </Menu>
      </div>
    </>
  )
}
