import React, { useMemo, useState, memo, useEffect, useCallback, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { notification, Skeleton, Typography, Row, Col, Select as AntSelect } from 'antd'
import { Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

import useQueryRequest from '../../common/hooks/useQueryRequest'
import useCommandRequest from '../../common/hooks/useCommandRequest'
import generateFriendlyUrl from '../../common/generateFriendlyUrl'
import { parseContent, SelectPopUpTemplate } from './SelectPopUpTemplate'
import {
  getDefaultPageSection,
  getPageSectionForSave,
  POPUP_TEMPLATES,
  savePageSection,
} from 'core/application/dashboard/storeSettings/pageSection'
import FormikCodeEditor from '../../common/components/dataEntry/formik/FormikCodeEditor'
import Card from '../../common/components/dataDisplay/Card'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import { useTranslation } from 'react-i18next'
import FormikInput from 'common/components/dataEntry/formik/FormikInputField'
import FormSaveCancelButtons from '../../common/components/forms/SaveCancelFormButtons'
import DynamicInputsList from 'common/components/dataEntry/components/dynamicInputs'
import { InputDescriptor } from 'common/components/dataEntry/components/dynamicInputs/DynamicInput'
import FormikDynamicHtmlEditor from 'common/components/dataEntry/formik/FormikDynamicHtmlEditor'
import { useQueryRequestV2 } from 'common/hooks'

const { Text } = Typography
const { Option } = AntSelect
export const MANAGE_PAGE_SECTION_PATH = 'manage-page-section'

export enum PageSectionStatus {
  Active = 0,
  Archive = 1,
  Draft = 2,
}

const formValidator = () =>
  Yup.object({
    name: Yup.string().required('Field is required.').nullable(),
    code: Yup.string().required('Field is required.').nullable(),
    content: Yup.string().required('Field is required.').nullable(),
    status: Yup.number().required('Field is required.').nullable(),
  })

const ManagePageSection = () => {
  const { t } = useTranslation()
  const { id } = useParams<any>()
  const history = useHistory()

  const [pageSection, isLoading] = useQueryRequest<any>({}, () => getPageSectionForSave(id ?? null))
  const [sendCommandRequest, isSubmittingCommand] = useCommandRequest()

  const handleSubmit = async (values: any) => {
    const content = parseContent(values.content)
    const request = { ...values, content }

    const result = await sendCommandRequest(() => savePageSection(request))
    const message = id ? 'Pop Up updated successfully.' : 'Pop Up created successfully.'

    if (result.completed) {
      notification.success({ message })
      history.goBack()
    }
  }

  return (
    <Skeleton active loading={isLoading} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {pageSection && (
        <Formik enableReinitialize validationSchema={formValidator} initialValues={pageSection} onSubmit={() => {}}>
          {({ values, setFieldValue, isValid, dirty }) => (
            <div>
              <Card
                bordered={false}
                title={id ? t('dashboard.store_settings.edit_pop_up') : t('dashboard.store_settings.add_pop_up')}
              >
                <Form>
                  <Row gutter={16}>
                    <Col span={12}>
                      <FormikInput
                        label={t('dashboard.store_settings.edit_page_form.title')}
                        name="name"
                        onBlur={(e: any) => {
                          setFieldValue('code', generateFriendlyUrl(e.currentTarget.value))
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <FormikInput label="Code" name="code" id="code" />
                    </Col>
                  </Row>

                  <DefaultPopupSelector id={id} />

                  <FormikDynamicHtmlEditor
                    name="content"
                    label={t('dashboard.store_settings.edit_page_form.content')}
                    originalContent={pageSection}
                  />

                  <div className={'mt-48'}>
                    <Select
                      size="large"
                      placeholder="Select status"
                      defaultValue={pageSection.status}
                      id={'status'}
                      name={'status'}
                    >
                      <option key={0} value={0}>
                        Active
                      </option>
                      <option key={1} value={1}>
                        Archived
                      </option>
                      <option key={2} value={2}>
                        Draft
                      </option>
                    </Select>
                  </div>
                </Form>
              </Card>
              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!(isValid && (dirty || id))}
                  onButtonClick={() => handleSubmit(values as any)}
                  loading={isSubmittingCommand}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

const DefaultPopupSelector = ({ id }: { id: string }) => {
  const { setFieldValue } = useFormikContext()

  const { data: defaultPopUps } = useQueryRequestV2<{ id: string; title: string; content: string }[]>({
    initialState: [],
    queryFn: getDefaultPageSection,
    enable: !id,
  })

  if (id) return null

  return (
    <>
      {defaultPopUps && defaultPopUps?.length > 0 && (
        <AntSelect
          placeholder="Select template"
          onSelect={(value: string, options: any) => {
            if (options.key === POPUP_TEMPLATES.defaultPopUp) {
              setFieldValue('content', value)
            }
          }}
          size="large"
        >
          {defaultPopUps.map((c) => (
            <Option key={c.id} value={c.content}>
              {c.title}
            </Option>
          ))}
        </AntSelect>
      )}
    </>
  )
}

export default ManagePageSection
